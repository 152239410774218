import React from 'react';
import gif from '../../images/Hexomatic_Translation.gif';
import {rightArrow} from '../../images/svgIcons';

import './styles.scss';

const credits = [
  'ChatGPT',
  'DeepL & Google Translate',
  'Google & Google Maps data',
  'Email enrichment',
  'Residential proxies',
  'Amazon Data',
  'SEO backlink data',
];
const PremiumCreditsTop = () => (
  <div className="row premium-credits-page mt-4">
    <div className="col-12 col-md-7">
      <h3 className="f_size_22 f_700 t_color3 l_height40 mb_10">
        Save time &amp; delegate tasks inside your workflows
      </h3>
      <div>
        Tap into premium machine translation, find outbound leads, enrich leads with contact information and access
        residential proxies right inside your Hexomatic workflows.
      </div>
      <div className="my-3 d-flex flex-wrap">
        <h3 className="f_size_22 pt-3 f_500">This add-on includes discounted access to</h3>
        <div className="d-flex flex-wrap">
          {credits.map((item: string) => (
            <div className="credits">
              <span className="pr-1 w-100">{rightArrow}</span> {item}
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex my-2">
        <div className="mr-4">
          <div>Starting from just</div>
          <div className="f_size_24 l_height40 d-flex">
            <div>
              <sup style={{fontSize: '14px'}}>$</sup>9<sup style={{fontSize: '14px'}}>99</sup>/
              <span className="f_size_14">monthly providing a 105 premium credit allowance</span>
            </div>
          </div>
          <div className="d-flex align-items-center"></div>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-5 text-center">
      <img src={gif} alt="credits" />
    </div>
  </div>
);

export default PremiumCreditsTop;
